.header-search-bar .ant-input-wrapper .ant-input-group-addon:nth-child(1) {
    border-radius: 20px 0px 0px 20px;
    background-color: white;
}

.header-search-bar .ant-input-search-button{
    border-radius: 0px 20px 20px 0px !important;
    height: 44px;
}

/* .header-search-bar .ant-input::placeholder {
    animation: topToBottom 12.5s linear infinite 0s;
  } */
  
  .header-search-bar .ant-input::placeholder {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }
  
  .header-search-bar .ant-input::placeholder {
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .mic-container {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .mic-icon {
    font-size: 40px;
    z-index: 2;
    position: relative;
  }
  
  .oscillation {
    position: absolute;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ea3857;
    border-radius: 50%;
  }
  
  .circle {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid #d7d5d5;
    border-radius: 50%;
    animation: oscillate 1.5s infinite ease-in-out;
    opacity: 0.5;
  }
  
  .circle:nth-child(2) {
    animation-delay: 0.3s;
    opacity: 0.4;
  }
  
  .circle:nth-child(3) {
    animation-delay: 0.6s;
    opacity: 0.3;
  }
  
  @keyframes oscillate {
    0% {
      transform: scale(1);
      opacity: 0.5;
    }
    50% {
      transform: scale(1.5);
      opacity: 0.2;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
  