.address-list {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .address-item {
    cursor: pointer;
    padding: 10px;
    transition: background-color 0.2s, border 0.2s;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .address-item:hover {
    background-color: #e6f7ff;
  }
  
  .address-item.selected {
    border-color: #1890ff;
    background-color: #e6f7ff;
  }
  
  .spinner-container {
    text-align: center;
    padding: 20px;
  }
  