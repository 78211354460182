.fixed-buttons {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 20px;
    align-items: center;
    z-index: 1000;
  }

  .mic-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  /* .mic-btn {
    background-color: #05386b;
    color: white;
    border: none;
    width: 60px;
    height: 60px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  
  .mic-btn.active {
    background-color: red;
  } */
  
  .cancel-btn {
    background-color: white;
    color: black;
    border: 1px solid #ccc;
    width: 60px;
    height: 60px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  .mic-container {
    margin: 0px !important;
     height: 40px !important; 
  }
  .mic-wave {
    width: 200px !important;  
   
  }
  .mic-btn {
    position: relative;
    transition: all 0.3s ease;
  }
  
  .mic-btn:not(.active)::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    background-color: rgba(78, 224, 224, 0.6);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: pulse 1.5s infinite ease-in-out;
    z-index: -1;
  }
  
  @keyframes pulse {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.7;
    }
    50% {
      transform: translate(-50%, -50%) scale(1.3);
      opacity: 0.4;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.7;
    }
  }
  
  
  

  
  