@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.ant-menu-horizontal {
  border-bottom: 0;
}

.ant-menu-title-content {
  color: #676c80;
  font-size: 16px;
}

.ant-menu-light .ant-menu-submenu-title {
  display: flex;
  flex-direction: row-reverse;
}

.ant-menu .ant-menu-submenu-title .ant-menu-item-icon + span {
  margin-inline-start: 0;
  margin-inline-end: 10px;
}

.ant-menu-light.ant-menu-horizontal
  > .ant-menu-submenu-open
  .ant-menu-item-icon {
  transform: rotate(180deg);
}

.ant-menu-item-selected .ant-menu-title-content,
.ant-menu-light.ant-menu-horizontal
  > .ant-menu-submenu-selected
  .ant-menu-title-content {
  color: #134171;
  font-weight: 600;
}
.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title {
  color: #134171;
}
.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after,
.ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item-selected::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected::after,
.ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu-selected::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after,
.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu:hover::after {
  border-bottom: 0;
}

.ant-menu-submenu-popup .ant-menu-vertical > .ant-menu-item {
  line-height: 30px;
  height: 30px;
}

.ant-menu-submenu-popup
  .ant-menu-vertical
  > .ant-menu-item
  .ant-menu-title-content {
  font-size: 14px;
}

.slick-prev,
.slick-next {
  width: 50px;
  height: 50px;
}

.slick-list,
.slick-slider {
  width: 90%;
  margin: 0 auto;
}

.homeTab.ant-tabs .ant-tabs-tab {
  background-color: #fff;
  border-radius: 5px;
}

.homeTab.ant-tabs .ant-tabs-tab-active {
  background-color: #134171;
  border-radius: 5px;
}

.homeTab.ant-tabs .ant-tabs-tab-btn {
  font-size: 18px;
  color: #134171;
}

.homeTab.ant-tabs .ant-tabs-tab-btn .tabimg {
  background-color: #134171;
}

.homeTab.ant-tabs .ant-tabs-tab:hover {
  background-color: #b1dff8;
}

.homeTab.ant-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #134171 !important;
}

.homeTab.ant-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

.homeTab.ant-tabs .ant-tabs-tab-active .ant-tabs-tab-btn .tabimg {
  background-color: #fff;
}

.homeTab.ant-tabs-left > .ant-tabs-content-holder,
.homeTab.ant-tabs-left > div > .ant-tabs-content-holder {
  border-left: 0;
}

.homeTab.ant-tabs .ant-tabs-ink-bar {
  display: none;
}

.homeTab.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  padding: 13px 24px;
}

.homeTab.ant-tabs .ant-tabs-tabpane {
  margin-left: 30px;
}

.homeTab.ant-tabs .ant-tabs-content.ant-tabs-content-left {
  height: 100%;
}

.homeCollapse.ant-collapse {
  border: 0;
}

.firstchildcollapse .ant-collapse-item:first-child .ant-collapse-content-box {
  height: 500px;
  overflow: scroll;
}

.homeCollapse.ant-collapse > .ant-collapse-item {
  background-color: #fff;
  border-bottom: 0;
  border-radius: 10px;
  margin-bottom: 20px;
}

.homeCollapse.ant-collapse .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}

.homeCollapse.ant-collapse .ant-collapse-header-text {
  font-size: 20px;
  color: #134171;
  font-weight: 600;
}

.homeCollapse.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  font-size: 18px;
  transform: rotate(90deg);
  font-weight: 800;
  color: #134171;
}

.homeCollapse.ant-collapse
  > .ant-collapse-item.ant-collapse-item-active
  > .ant-collapse-header
  .ant-collapse-arrow {
  transform: rotate(180deg);
}

.homeCollapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center;
}

.homeSearch .ant-input {
  height: 50px;
  border-radius: 10px;
  border: 0;
}

.homeSearch .ant-input-group-addon {
  background-color: #134171;
  border-radius: 10px !important;
  width: 90px;
}

.homeSearch .ant-input-search-button {
  font-size: 24px;
  padding: 0;
  line-height: 24px;
}

.faqCollapse.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
}

@media (min-width: 320px) and (max-width: 767px) {
  .faqCollapse.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  font-size: 18px;
  }
  .faqinner.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
    font-size: 16px !important;
  }
}
.faqLab.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
  font-size: 16px;
}


.faqinner.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  font-size: 18px;
  font-weight: 300;
}

.faqCollapse.ant-collapse {
  border: 0;
}

.dentaldiv3 .faqCollapse.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  font-size: 20px;
  transform: rotate(90deg);
  font-weight: 800;
  color: #000;
}
.dentaldiv3.faqCollapse.ant-collapse .ant-collapse-content {
  background-color: transparent;
  border-top: none;
}
/* .dentaldiv3 .faqCollapse .ant-collapse .ant-collapse-item{border-bottom: 2px dashed #000;} */
.faqSecond2.faqCollapse.ant-collapse > .ant-collapse-item {
  border-bottom: 1px dashed #E3E3E3;
}


.faqCollapse.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  font-size: 20px;
  transform: rotate(90deg);
  font-weight: 800;
  color: #fff;
}
.faqLab.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  font-size: 16px;
}

.faqinner.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  font-size: 16px;
}

.faqCollapse.ant-collapse
  > .ant-collapse-item.ant-collapse-item-active
  > .ant-collapse-header
  .ant-collapse-arrow {
  transform: rotate(180deg);
}

.faqCollapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 19px 16px;
}

.faqSecond.faqCollapse.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #ffffff5e;
}

.faqCollapse.ant-collapse .ant-collapse-content {
  background-color: transparent;
  border-top: 1px solid #ffffff5e;
}


.faqSecond.ant-collapse .ant-collapse-content {
  padding: 16px 55px !important;
}
.faqSecond2.ant-collapse .ant-collapse-content {
  padding: 10px 15px !important;
}
.faqSecond2.ant-collapse .ant-collapse-item:last-child >.ant-collapse-content {
  border-radius: 0 !important;
}

.faqSecond2.ant-collapse>.ant-collapse-item:last-child, .faqSecond2.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
  border-radius: 0 !important;
}


.faqSecond.ant-collapse .ant-collapse-item:last-child >.ant-collapse-content {
  border-radius: 0 !important;
}

.faqSecond.ant-collapse>.ant-collapse-item:last-child, .faqSecond.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
  border-radius: 0 !important;
}

.faqCollapse.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.faqinner.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 19px 45px;
}

.homeForm .ant-form-item .ant-form-item-label > label {
  color: #676c80;
  font-size: 16px;
}

.homeForm .ant-input {
  height: 50px;
  border: 0;
  font-size: 16px;
}

.homeForm .ant-input-number,
.CommonForm .ant-input-number {
  width: 100%;
}

.homeForm .ant-input-number-input,
.CommonForm .ant-input-number-input {
  height: 50px;
}

.CommonForm .ant-form-item .ant-form-item-label > label {
  color: #134171;
  font-size: 14px;
}

.CommonForm .ant-input {
  height: 50px;
  font-size: 14px;
}

.SureryForm .ant-input {
  height: 30px;
  font-size: 16px;
}


.CommonForm .ant-select-single, .CommonForm .ant-picker {
  height: 50px;
  border: 1px solid #cbd5e1;
  border-radius: 5px;
}

/* .CommonForm .ant-form-item {
  margin-bottom: 0;
} */

.commonSegment.ant-segmented {
  padding: 5px;
  background-color: #134171;
  color: #fff;
  border-radius: 50px;
}

.commonSegment.ant-segmented .ant-segmented-item {
  padding: 8px;
  font-size: 16px;
  font-weight: 600;
}

.commonSegment.ant-segmented .ant-segmented-item-selected {
  color: #134171;
  border-radius: 50px;
}

.commonSegment.ant-segmented
  .ant-segmented-item:hover:not(.ant-segmented-item-selected):not(
    .ant-segmented-item-disabled
  ) {
  color: #fff;
}

.commonSegment.ant-segmented .ant-segmented-thumb {
  border-radius: 50px;
  color: #134171 !important;
}

.apptSearch.ant-input-group-wrapper {
  height: 50px;
  background: #ebf8ff;
  border-radius: 50px;
  border: 0;
}

.apptWhite.apptSearch.ant-input-group-wrapper,
.apptWhite.ant-input-group-wrapper .ant-input-group .ant-input {
  background-color: #fff !important;
}

.apptSearch.ant-input-group-wrapper .ant-input-group {
  flex-direction: row-reverse;
  display: flex;
  align-items: center;
}

.apptSearch.ant-input-group-wrapper .ant-input-group .ant-input {
  height: 50px;
  background: #ebf8ff;
  border: 0;
  border-radius: 50px;
  box-shadow: none;
  font-size: 16px;
}

.apptSearch.ant-input-group-wrapper .ant-input-group-addon {
  width: 46px;
  height: 40px;
  background: #134171;
  border-radius: 50px;
  margin-left: 7px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.apptSearch.ant-input-group-wrapper .ant-input-search-button {
  background: transparent;
  border: 0;
  padding: 0;
  width: 100%;
  margin: 0;
}

.apptSearch.ant-input-group-wrapper .ant-input-search-button:hover {
  background-color: transparent;
}

.apptSearch.ant-input-group-wrapper .ant-btn-icon {
  color: #fff;
}

.uniqueSearch.ant-input-group-wrapper {
  height: 50px;
  border-radius: 30px;
}

.uniqueSearch.ant-input-group-wrapper .ant-input-group .ant-input {
  height: 50px;
  border: 0;
  border-radius: 30px;
}

.uniqueSearch.ant-input-search >.ant-input-group >.ant-input-group-addon:last-child .ant-input-search-button {
  height: 50px;
  border-radius: 50px;
  width: 57px;
  font-size: 18px;
}

.uniqueSearch.ant-input-group-wrapper-outlined .ant-input-group-addon {
  background-color: transparent;
  border: 0;
}
.custom-flex{
  display: flex !important;
}

.commonModal .ant-modal-content {
  padding: 0
}

.commonModal .ant-modal-content .ant-modal-header {
  background-color: #134171;
  padding: 25px;
  color: #fff;
}

.commonModal .ant-modal-body {
  padding: 25px;
}

.commonModal .ant-modal-content .ant-modal-header .ant-modal-title,
.commonModal .ant-modal-close {
  color: #fff;
}
.commonModal .ant-modal-close {
 top: 27px;
}
.custom-table-header th{
  padding:5px;
  border: 1px solid theme('colors.textGray');
  
}
.custom-table-row td{
  padding: 8px 2px;
  border: 1px solid theme('colors.textGray');
  color: theme('colors.textGray');
}

@media (min-width: 320px) and (max-width: 676px) {
  .container {
    width: 90%;
  }
  .slick-prev,
  .slick-next {
    top: 105%;
  }
  .splSlider .slick-prev,
  .splSlider .slick-next {
    top: 111%;
  }
  .slick-prev {
    left: 32%;
  }
  .slick-next {
    right: 32%;
  }
  .slick-list {
    margin: 0;
    width: 100%;
    text-align: center;
  }
  .slick-prev, .slick-next {
    width: 35px;
    height: 35px;
}
.slick-dots li button:before{
  font-family: none !important;
  font-size: 20px !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
}
  .homeCollapse.ant-collapse .ant-collapse-header-text {
    font-size: 18px;
  }
  .commonSegment.ant-segmented .ant-segmented-item {
    padding: 6px;
    font-size: 13px;
    font-weight: 600;
  }
  .custom-flex{
    display: block !important;
  }
  .ant-card-body{
    width: 100%;
  }
  .ant-modal-root .ant-modal {
    width: 90% !important;
  }

}

.applycoupon .ant-input-affix-wrapper-lg {
  height: 50px;
  border-right: 0;
  outline: 0;
}

.applycoupon.ant-input-search >.ant-input-group >.ant-input-group-addon:last-child {
  border: 1px solid #d9d9d9;
  border-left: 0;
  background-color: transparent;
}

.applycoupon.ant-input-search >.ant-input-group >.ant-input-group-addon:last-child button {
  color: #42BA85;
}
.applycoupon.ant-input-search >.ant-input-group >.ant-input-group-addon:last-child button:hover {
  background-color: transparent;
}

.loginmodal .ant-modal-content {
  background-color: #F3F8FF;
}
@media (min-width: 768px) and (max-width: 1480px) {
  .container {
    width: 90%;
    max-width: 90%;
  }

}

.commonSteps.ant-steps .ant-steps-item-icon {
  display: none;
}

.commonSteps .ant-steps-item-content>.ant-steps-item-title {
  color: #fff !important;
  font-size: 18px;
}

/* .commonSteps.ant-steps .ant-steps-item-container {
  text-align: center;
} */

.commonSteps.ant-steps .ant-steps-item-finish .ant-steps-item-content>.ant-steps-item-title {
  color: #42BA85 !important;
  font-weight: 600;
}

.commonSteps.ant-steps .ant-steps-item {
  flex: auto;
}

.commonSteps.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #42BA85;
  height: 2px;
}

.cartCheck .ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.cartCheck .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #42BA85;
  border-color: #42BA85;
}

.ant-btn-primary {
  background-color: #134171;
}
/* Loader.css */

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the loader is on top of other content */
}

.loader-container {
  text-align: center;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Apply rotation animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* .card2 {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 10px;
  max-width: 300px;
}



.card2-image {
    width: 100%;
    height: 200px;
}

.card2-content {
    padding: 15px;
    background-color: #fff;
    margin-top: -106px;
    position: absolute;
    
}

.card2-title {
    margin: 0;
    font-size: 1.2em;
}

.card2-location {
    color: #888;
    font-size: 0.9em;
} */
.card2 {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 10px;
  max-width: 300px;
  position: relative; /* Relative positioning for absolute child */
}

.card2-image {
  width: 100%;
  height: 200px;
  object-fit: cover; /* Ensure the image covers the entire area */
}

.card2-content {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: flex-start; /* Align content to the left */
  padding: 10px;
  background-color: white;
  border-radius: 10px; /* Rounded corners */
  position: absolute;
  bottom: 55px; /* Position the content at the bottom with a small margin */
  left: 50%;
  transform: translateX(-50%); /* Center the content horizontally */
  width: 90%; /* Make the content take up most of the width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add a slight shadow */
}

.card2-title {
  margin: 0;
  font-size: 1.2em;
  font-weight: bold; /* Make the title bold */
}

.card2-location {
  color: #555; /* Slightly darker color for the location text */
  font-size: 0.9em;
  margin-top: 5px; /* Small margin to separate the location from the title */
}
.mobileview{display: none;}
.dentalcarediv1{height: 40px;
  width: 40px;
  border-radius: 19px;
  font-size: 25px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;}
@media(max-width:500px){
  .mobileview{display: block;}
  .desktopview{display: none;} 
  .dentalcarediv1 {
    line-height: 28px;
    height: 30px;
    width: 30px;
    border-radius: 19px;
    font-size: 17px;
    padding-left: 10px;
    padding-right: 12px;
}
}

body {
  margin: 0;
}
.join-room {
  max-width: 380px;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  padding: 15px;
}
.join-room .logo {
  width: 70px;
}
.join-room input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #fff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
  margin-top: 15px;
}
.join-room .join-channel {
  color: #fff;
  background-color: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  font-size: 14px;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
  outline: none;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: manipulation;
  line-height: 1.5714285714;
  box-sizing: border-box;
  overflow: visible;
  margin-top: 10px;
}
.join-room .join-channel.disabled {
  background-color: #d9d9d9;
  box-shadow: none;
}
.room {
  padding-top: 10px;
}
.user-list {
  padding-top: 1rem;
  padding: 2.5rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  flex: 1 1 0%;
  display: flex;
}
.user {
  /* border-style: solid; */
  /* --un-border-opacity: 1; */
  /* border-color: rgba(75, 85, 99, var(--un-border-opacity)); */
  /* border-width: 1px; */
  /* box-sizing: border-box; */
  width: "100%";
  min-height: 216px;
}
.user-name {
  --un-text-opacity: 1;
  color: rgba(255, 255, 255, var(--un-text-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  --un-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--un-bg-opacity));
  grid-gap: 0.25rem;
  gap: 0.25rem;
  align-items: center;
  display: inline-flex;
  z-index: 2;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .video-grid {
      
  }
}

.control {
  --un-text-opacity: 1;
  color: rgba(209, 213, 219, var(--un-text-opacity));
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --un-bg-opacity: 1;
  background-color: rgba(33, 36, 44, var(--un-bg-opacity));
  grid-gap: 0.75rem;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
}
.left-control {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  align-items: center;
  flex: 1 1 0%;
  display: flex;
  height: 100%;
}
.i-microphone {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M12 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3m7 9c0 3.53-2.61 6.44-6 6.93V21h-2v-3.07c-3.39-.49-6-3.4-6-6.93h2a5 5 0 0 0 5 5a5 5 0 0 0 5-5h2Z'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em;
  font-size: 1.2rem;
}
.i-microphone.off {
  opacity: 0.2;
}
.i-camera {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M4 4h3l2-2h6l2 2h3a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2m8 3a5 5 0 0 0-5 5a5 5 0 0 0 5 5a5 5 0 0 0 5-5a5 5 0 0 0-5-5m0 2a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3Z'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em;
  font-size: 1.2rem;
}
.i-camera.off {
  opacity: 0.2;
}
.btn {
  display: inline-flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  align-items: center;
  font-size: 0;
  color: inherit;
}
.btn-phone {
  border-radius: 0.55rem;
  padding: 0.5rem 4rem;
  color: #fff;
  background-color: #16a34a;
}
.btn-phone-active {
  background-color: #dc2626;
}
.btn .btn-phone {
  border-radius: 0.55rem;
  padding: 0.5rem 4rem;
  color: #fff;
  background-color: #16a34a;
}
.btn .btn-phone-active {
  background-color: #dc2626;
}
.i-phone-hangup {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M12 9c-1.6 0-3.15.25-4.6.72v3.1c0 .4-.23.74-.56.9c-.98.49-1.87 1.12-2.67 1.85c-.17.18-.42.29-.67.29c-.3 0-.55-.12-.73-.3L.29 13.08a.99.99 0 0 1-.29-.7c0-.28.11-.53.29-.71C3.34 8.77 7.46 7 12 7c4.54 0 8.66 1.77 11.71 4.67c.18.18.29.43.29.71c0 .27-.11.52-.29.7l-2.48 2.48c-.18.18-.43.3-.73.3a.98.98 0 0 1-.68-.29c-.79-.73-1.68-1.36-2.66-1.85a.996.996 0 0 1-.56-.9v-3.1C15.15 9.25 13.6 9 12 9Z'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1em;
  height: 1em;
  font-size: 1.2rem;
}
.i-phone {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M6.62 10.79c1.44 2.83 3.76 5.15 6.59 6.59l2.2-2.2c.28-.28.67-.36 1.02-.25c1.12.37 2.32.57 3.57.57a1 1 0 0 1 1 1V20a1 1 0 0 1-1 1A17 17 0 0 1 3 4a1 1 0 0 1 1-1h3.5a1 1 0 0 1 1 1c0 1.25.2 2.45.57 3.57c.11.35.03.74-.25 1.02l-2.2 2.2Z'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1em;
  height: 1em;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  :where(.css-dev-only-do-not-override-apn68).ant-card .ant-card-body {
    padding: 15px;
    border-radius: 0 0 8px 8px;
  }
  

}

.custom-popover {
  max-width: 90%; /* Ensure the popover fits on smaller screens */
  word-wrap: break-word; /* Prevent text overflow */
}

@media (max-width: 768px) {
  .ant-popover {
    width: auto !important; /* Ensure the popover adjusts to screen size */
  }
  .ant-popover-arrow {
    display: none; /* Hide the arrow for better alignment on mobile */
  }
}

.p-tooltip {
  max-width: 80%; /* Ensure the tooltip fits within smaller screens */
  word-wrap: break-word;
  white-space: normal; /* Allow multi-line tooltips */
}

@media (max-width: 768px) {
  .p-tooltip {
    font-size: 12px; /* Adjust font size for mobile */
  }
  .coupon-tooltip {
    position: relative;
  }
}
.custom-input-number .ant-input-number-handler-wrap {
  display: none;
}
.custom-input-number .ant-input-number-input {
  text-align: left; 
}

