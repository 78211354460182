.sentence{
    color: #8E9AAB;
    font-size: 15px;
    text-align: left;
    display: block;
  }
  
  .sentence > span {
    display: inline-block;
    margin-right: 5px;
    margin-bottom:0.5em;
  }
  /*Wrapper*/
  .wrapper{
      background-color: transparent;
      font-family: 'Cabin', sans-serif;
      margin: 100px auto;
      padding: 0px 0px;
      position: relative;
      width: 100%;
  }
  
  /*Vertical Sliding*/
  .slidingVertical{
    display: inline-block;
   
    height:1.2em;
    vertical-align: top;
      
  }
  .slidingVertical span{
       animation: topToBottom 12.5s linear infinite 0s;
      -ms-animation: topToBottom 12.5s linear infinite 0s;
      -webkit-animation: topToBottom 12.5s linear infinite 0s;
      color: #8E9AAB;
       opacity: 0;
       overflow: hidden;
      position: absolute;
  }
  .slidingVertical span:nth-child(2){
      animation-delay: 2.5s;
      -ms-animation-delay: 2.5s;
      -webkit-animation-delay: 2.5s;
  }
  .slidingVertical span:nth-child(3){
      animation-delay: 5s;
      -ms-animation-delay: 5s;
      -webkit-animation-delay: 5s;
  }
  .slidingVertical span:nth-child(4){
      animation-delay: 7.5s;
      -ms-animation-delay: 7.5s;
      -webkit-animation-delay: 7.5s;
  }
  .slidingVertical span:nth-child(5){
      animation-delay: 10s;
      -ms-animation-delay: 10s;
      -webkit-animation-delay: 10s;
  }

  .slidingVertical span:nth-child(6){
    animation-delay: 12.5s;
    -ms-animation-delay: 12.5s;
    -webkit-animation-delay: 12.5s;
}
  
  /*topToBottom Animation*/
  @-moz-keyframes topToBottom{
      0% { opacity: 0; }
      5% { opacity: 0; -moz-transform: translateY(-20px); }
      10% { opacity: 1; -moz-transform: translateY(0px); }
      25% { opacity: 1; -moz-transform: translateY(0px); }
      30% { opacity: 0; -moz-transform: translateY(20px); }
      80% { opacity: 0; }
      100% { opacity: 0; }
  }
  @keyframes topToBottom{
      0% { opacity: 0; }
      5% { opacity: 0; -webkit-transform: translateY(-20px); }
      10% { opacity: 1; -webkit-transform: translateY(0px); }
      25% { opacity: 1; -webkit-transform: translateY(0px); }
      30% { opacity: 0; -webkit-transform: translateY(20px); }
      80% { opacity: 0; }
      100% { opacity: 0; }
  }
  @-ms-keyframes topToBottom{
      0% { opacity: 0; }
      5% { opacity: 0; -ms-transform: translateY(-20px); }
      10% { opacity: 1; -ms-transform: translateY(0px); }
      25% { opacity: 1; -ms-transform: translateY(0px); }
      30% { opacity: 0; -ms-transform: translateY(20px); }
      80% { opacity: 0; }
      100% { opacity: 0; }
  }
  
  /*Vertical Flip*/
  .verticalFlip{
      display: inline;
      text-indent: 14px;
  }
  .verticalFlip span{
      animation: vertical 10s linear infinite 0s;
      -ms-animation: vertical 10s linear infinite 0s;
      -webkit-animation: vertical 10s linear infinite 0s;
      color: #8E9AAB;
      opacity: 0;
      overflow: hidden;
      position: absolute;
  }
  .verticalFlip span:nth-child(2){
      animation-delay: 2.5s;
      -ms-animation-delay: 2.5s;
      -webkit-animation-delay: 2.5s;
  }
  .verticalFlip span:nth-child(3){
      animation-delay: 5s;
      -ms-animation-delay: 5s;
      -webkit-animation-delay: 5s;
  }
  .verticalFlip span:nth-child(4){
      animation-delay: 7.5s;
      -ms-animation-delay: 7.5s;
      -webkit-animation-delay: 7.5s;
  }
  .verticalFlip span:nth-child(5){
      animation-delay: 10s;
      -ms-animation-delay: 10s;
      -webkit-animation-delay: 10s;
  }
  
  /*Vertical Flip Animation*/
  @-moz-keyframes vertical{
      0% { opacity: 0; }
      5% { opacity: 0; -moz-transform: rotateX(180deg); }
      10% { opacity: 1; -moz-transform: translateY(0px); }
      25% { opacity: 1; -moz-transform: translateY(0px); }
      30% { opacity: 0; -moz-transform: translateY(0px); }
      80% { opacity: 0; }
      100% { opacity: 0;}
  }
  @keyframes vertical{
      0% { opacity: 0; }
      5% { opacity: 0; -webkit-transform: rotateX(180deg); }
      10% { opacity: 1; -webkit-transform: translateY(0px); }
      25% { opacity: 1; -webkit-transform: translateY(0px); }
      30% { opacity: 0; -webkit-transform: translateY(0px); }
      80% { opacity: 0; }
      100% { opacity: 0; }
  }
  @-ms-keyframes vertical{
      0% { opacity: 0; }
      5% { opacity: 0; -ms-transform: rotateX(180deg); }
      10% { opacity: 1; -ms-transform: translateY(0px); }
      25% { opacity: 1; -ms-transform: translateY(0px); }
      30% { opacity: 0; -ms-transform: translateY(0px); }
      80% { opacity: 0; }
      100% { opacity: 0; }
  }