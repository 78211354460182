.modal-container {
    padding: 20px;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .date-selector {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    overflow-x: auto;
  }
  
  .date-button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .date-button.active {
    background-color: #e7f3ff;
    font-weight: bold;
  }
  
  .slots-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
  }
  
  .slots-column {
    flex: 1;
    min-width: 150px;
  }
  
  .slot-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .slot {
    padding: 5px;
    text-align: center;
    border-radius: 8px;
  }
  
  .slot.available {
    cursor: pointer;
    background-color: #ffffff;
  }
  
  .slot.unavailable {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
  
  .slot.selected {
    background-color: #e7f3ff;
    font-weight: bold;
  }
  
  @media screen and (max-width: 768px) {
    .slots-container {
      flex-direction: column;
    }
  
    .slots-column {
      width: 100%;
    }
  
    .date-selector {
      flex-wrap: wrap;
    }
  }

  .custom-sidebar {
    width: 100% !important; /* Default full-screen width for mobile */
  }
  
  @media (min-width: 1024px) {
    .custom-sidebar {
      width: 40% !important; /* 60% width for larger screens (web) */
    }
  }
  

  