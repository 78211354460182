.mic-recorder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .mic-button {
    font-size: 8px;
    padding: 8px;
    border: none;
    border-radius: 50%;
    /* background-color: #3498db; */
    /* color: white; */
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    position: relative;
    z-index: 10;
  }
  
  .mic-button:hover {
    background-color: #2980b9;
  }
  
  .mic-button.recording {
    background-color: red;
    color: white;
    /* color: white; */
  }
  
  .circle-animation {
    position: absolute;
    width: 30px;
    height: 30px;
    border: 2px solid red;
    border-radius: 50%;
    animation: none;
    z-index: 1;
  }
  
  .circle-animation.active {
    animation: pulsate 2s infinite ease-in-out;
  }

  .recording-waveform{
    animation: pulsate1 0.8s infinite ease-in-out;
  }

  @keyframes pulsate1 {
    0% {
      transform: scaleY(1);
      opacity: 1;
    }
    50% {
      transform: scaleY(1.5);
      opacity: 1;
    }
    100% {
      transform: scaleY(2);
      opacity: 1;
    }
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.5);
      opacity: 0.7;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
  
  .audio-container {
    /* margin-top: 20px; */
    text-align: center;
  }
  
  audio {
    /* margin-top: 10px; */
    outline: none;
  }

  .waveform-container{
    display: flex;
    background-color: rgb(84, 137, 236);
    width: max-content;
    align-items: center;
    border-radius: 30px;
    padding: 0px 10px;
    margin-right: 10px;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }

  .close-icon-button{
    background-color: #005D8E;
    border-radius: 50%;
    color: white;
    padding: 2px;
    cursor: pointer;
    font-size: 20px;
    margin-right: 4px;
  }

  .done-icon-button{
    background-color: #005D8E;
    border-radius: 50%;
    color: white;
    padding: 2px;
    cursor: pointer;
    font-size: 20px;
    margin-left: 4px;
  }

  .language-button{
    border: 1px solid gray;
    color: black;
    font-size: 12px;
    font-weight: 700;
  }

  .language-button:hover{
    background-color: #004171;
    color: white;
  }
  