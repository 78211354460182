.video-recorder-container {
  position: relative;
 
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-recorder-circle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; 
}

.video-recorder-circle-progress {
  stroke: #4caf50; 
  transition: stroke-dashoffset 0.5s ease-out;
}

.video-recorder-video {
  position: absolute;
  top: 10px; 
  left: 10px;
  border-radius: 50%;
  object-fit: cover;
  width: 400px;
  height: 400px;
  border: none;
  overflow: hidden;
  transform: scaleX(-1);
}

.countdown-animation-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.countdown-number {
  font-size: 10rem; 
  font-weight: bold;
  color: #ffffff; 
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.8), 0 0 40px rgba(255, 255, 255, 0.6);
  animation: scaleFade 1s ease-in-out;
}

@keyframes scaleFade {
  0% {
    transform: scale(2);
    opacity: 0;
  }
  50% {
    transform: scale(1); 
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .video-recorder-container-mobile {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    overflow: hidden;
  }

  .video-recorder-video-mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    transform: scaleX(-1);
    z-index: 1;
  }

  .countdown-circle-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    z-index: 2;
  }

  .countdown-dotted-circle {
    width: 100%;
    height: 100%;
  }

  .countdown-dotted-circle circle {
    fill: none;
    stroke: #4caf50;
    stroke-width: 4;
    stroke-dasharray: 10, 5;
    animation: connectDots 3s linear forwards;
  }

  @keyframes connectDots {
    0% {
      stroke-dashoffset: 283;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
}



