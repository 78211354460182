.btncss {
  background-color: #265c89;
}
.sidebar {
  width: 400px !important;
}
.mobile_sidebar {
  height: 600px !important;
}

.primary_btn {
  background-color: #004171;
  color: #fff;
  font-size: 14px;
  width: 110px;
  height: 42px;
  padding: 12px;
  border-radius: 10rem;
}

.header-shadow {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  z-index: 100;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: white;
}
.swipicon {
  position: absolute;
  bottom: -55px;
  z-index: 5;
  left: 50%;
}

.searchbar {
  width: 100%; /* or the width it normally has */
  /* other styles */
}

/* Sticky state */
.sticky-searchbar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100; /* Ensure it stays on top of other content */
  /* other styles for sticky state */
}

.sectionTwo::before,
.sectionOne::before {
  content: "";
  background: url(./Assets/Images/rectangle.png);
  height: 100px;
  width: 100px;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  right: 0;
}
.sectionTwo::after {
  content: "";
  background: url(./Assets/Images/rectangle-light.png);
  height: 100px;
  width: 100px;
  position: absolute;
  background-repeat: no-repeat;
  bottom: -15px;
  right: 30%;
}

.progressOne::after {
  content: "";
  background: url(./Assets/Images//blue-line.svg);
  background-repeat: no-repeat;
  position: absolute;
  width: 147px;
  height: 10px;
  top: 16%;
  right: -67px;
}

.progressTwo::after {
  content: "";
  background: url(./Assets/Images//green-line.svg);
  background-repeat: no-repeat;
  position: absolute;
  width: 153px;
  height: 10px;
  top: 16%;
  right: -70px;
}

.image-boxshadow {
  box-shadow: 0px -5px 54.5px 0px #0000002b;
}

.tech-grid {
  display: grid;
  -ms-grid-columns: 1fr 2rem 1fr 2rem 1fr 2rem 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 10fr 2fr;
  /* -ms-grid-rows: auto 2rem auto 2rem auto 2rem auto; */
  grid-template-areas:
    "techOne techTwo techTwo techThree"
    "techOne techTwo techTwo techSeven"
    "techOne techFive techSix techSeven"
    "techFour techFive techSix techSeven";
  /* "techFour techFive techSix techSeven"; */
  grid-gap: 2rem;
}

.tech-grid #techOne {
  grid-area: techOne;
}
.tech-grid #techTwo {
  grid-area: techTwo;
}
.tech-grid #techThree {
  grid-area: techThree;
}
.tech-grid #techFour {
  grid-area: techFour;
}
.tech-grid #techFive {
  grid-area: techFive;
}
.tech-grid #techSix {
  grid-area: techSix;
}
.tech-grid #techSeven {
  grid-area: techSeven;
}

.common-shadow {
  box-shadow: 0px 3px 39.79999923706055px 0px #00000012;
}

.hoverWhite:hover h6,
.hoverWhite:hover p,
.hoverWhite:hover h5 {
  color: #fff;
}

.hoverGreen:hover h6 {
  color: #42ba85;
}

.image-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.award-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #004171;
  color: white;
  opacity: 0;
  transition: opacity 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container:hover .award-overlay {
  opacity: 1;
}

.imageShadow {
  box-shadow: 6px 4px 35.400001525878906px 0px rgba(0, 0, 0, 0.25);
}

.scrollWidth {
  scrollbar-width: none;
  -webkit-scrollbar: none;
}

.fixed-size-image {
  width: 400px; /* Set your desired width */
  height: 200px; /* Set your desired height */
  object-fit: cover; /* Preserve aspect ratio and cover the entire container */
}

/* :where(.css-dev-only-do-not-override-1rqnfsa).ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "";
} */
.doctor-image {
  margin: 0 auto;
  margin-left: 0;
  border-radius: 0.375rem;
  object-fit: cover;
  object-position: top;
  width: 350px;
  height: 360px;
}

.brightnessWhite:hover img {
  filter: brightness(7.5);
}

.labwidth {
  width: calc(100% - 20px) !important;
}

/* :where(.css-dev-only-do-not-override-1k979oh).ant-upload-wrapper
  .ant-upload-drag
  p.ant-upload-drag-icon
  .anticon {
  color: theme("colors.textGray");
} */

.topCardwidth {
  width: calc(100% - 24px) !important;
}

/* :where(.css-dev-only-do-not-override-1k979oh).ant-picker {
  outline: none;
  border: none;
} */

/* :where(.css-dev-only-do-not-override-1k979oh).ant-picker
  .ant-picker-input
  > input::placeholder {
  color: transparent;
  visibility: hidden;
} */

/* :where(.css-dev-only-do-not-override-1k979oh).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: none;
} */

/* .ant-picker-input > input::placeholder {
  color: transparent;
} */

/* Hide the calendar icon */
/* .ant-picker-suffix > .anticon-calendar {
  display: none;
} */
/* .ant-select-item-option {
  color: theme("colors.secondaryColor");
  font-size: 18px;
  font-weight: 500;
} */

@media (min-width: 768px) {
  .md\:w-1\/3 {
    width: 31.333333%;
  }
}
.ant-empty {
  display: none;
}
.custom-date-input {
  /* Add your custom styles here */
  /* For example: */
  width: 220px; /* Example: Set the width */
  border: 1px solid #ccc; /* Example: Add a border */
  border-radius: 7px; /* Example: Add border radius */
  padding: 1px; /* Example: Add padding */
  font-size: 16px;
  /* Add any other styles you need */
  transition: border-color 0.3s ease-in-out; /* Smooth transition */
  outline: none;
}

.custom-date-input:hover {
  border-color: #66afe9; /* Change border color on hover */
}

.sentence {
  color: #8e9aab;
  font-size: 15px;
  text-align: left;
  display: block;
}

.sentence > span {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 0.5em;
}

.loader {
  border-top-color: #3498db !important;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.slidingVertical {
  display: inline-block;

  height: 1.2em;
  vertical-align: top;
}

.slidingVertical span {
  animation: topToBottom 12.5s linear infinite 0s;
  -ms-animation: topToBottom 12.5s linear infinite 0s;
  -webkit-animation: topToBottom 12.5s linear infinite 0s;
  color: #8e9aab;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}
.slidingVertical span:nth-child(2) {
  animation-delay: 2.5s;
  -ms-animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
}
.slidingVertical span:nth-child(3) {
  animation-delay: 5s;
  -ms-animation-delay: 5s;
  -webkit-animation-delay: 5s;
}
.slidingVertical span:nth-child(4) {
  animation-delay: 7.5s;
  -ms-animation-delay: 7.5s;
  -webkit-animation-delay: 7.5s;
}
.slidingVertical span:nth-child(5) {
  animation-delay: 10s;
  -ms-animation-delay: 10s;
  -webkit-animation-delay: 10s;
}

.slidingVertical span:nth-child(6) {
  animation-delay: 12.5s;
  -ms-animation-delay: 12.5s;
  -webkit-animation-delay: 12.5s;
}

select:invalid {
  color: #9fa2ab;
}

@media (min-width: 320px) and (max-width: 676px) {
  .topCardwidth {
    width: calc(100% - 7px) !important;
  }
}

.hosptial-img-wh {
  width: 200px;
  height: 150px;
  margin-top: 8px;
}

input[type="file"] {
  content-visibility: hidden;
}
/* 
@media (max-width: 767px) {
  .card-image img {
      height: 55%;
  }
} */

/* For larger screens */
@media (min-width: 768px) {
  .card-image img {
    height: 150px;
  }
}

.pointed-border:nth-child(odd) {
  text-decoration: none;
  font-weight: bold;
  height: 100px;
  padding: 10px 10px;
  font-size: 14px;
  border-radius: 0 8px 8px 0;
  -webkit-border-radius: 0 8px 8px 0;
  float: left;
  margin-top: 20px;
  position: relative;
  color: #ffffff;
  border: 2px solid #44ba85;
  border-left: 0;
}
.pointed-border:nth-child(odd):after {
  content: "";
  width: 70px;
  height: 70px;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  display: block;
  position: absolute;
  top: 14px;
  left: -36px;
  z-index: 1;
  border: 2px solid #44ba85;
  border-top: none;
  border-right: none;
}

.pointed-border:nth-child(even) {
  text-decoration: none;
  font-weight: bold;
  height: 100px;
  padding: 10px 10px;
  font-size: 14px;
  border-radius: 8px 0px 0px 8px;
  -webkit-border-radius: 8px 0px 0px 8px;
  float: left;
  margin-left: 30px;
  margin-top: 20px;
  position: relative;
  color: #fff;
  border: 2px solid #44ba85;
  border-right: 0;
  background: -moz-linear-gradient(top, #eee 0%, #bbb 100%);
}

.pointed-border:nth-child(even):after {
  content: "";
  width: 70px;
  height: 70px;
  background: -moz-linear-gradient(left top, #eee 0%, #bbb 100%);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  display: block;
  position: absolute;
  top: 14px;
  right: -36px;
  z-index: 1;
  border: 2px solid #44ba85;
  border-bottom: none;
  border-left: none;
}

.newtable-list {width: 25%;
display: flex;
justify-content: flex-end;
flex-direction: column;}

.newtable input {width: 100%;
    border: 1px solid #e2e2e2!important;
    border-radius: 5px!important;}

.consentModal .ant-modal-content{
  padding: 20px 0px !important;
}

/* .consentModal .p-tabview-panels{
  padding: 20px 2px !important;
} */

.page-controls button {
  width: 44px;
  height: 44px;
  background: white;
  border: 0;
  font: inherit;
  font-size: 1em;
  border-radius: 4px;
}

.react-pdf__Page__canvas{
  width: -webkit-fill-available !important;

}
.react-pdf__Page{
  width: inherit !important;
  min-width: auto !important;

}
.react-pdf__Document{
  width: inherit;
}

.consentBodyContainer{
  height: inherit;
}

.consentBodyContainer > .p-tabview{
  height: 100% !important;
}

.consentBodyContainer > .consentcont{
  height: 100% !important;
}

#launcher-frame{
  z-index: 200 !important;
}

.textLayer {
  color: #333;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  padding: 0px 10px;
  height: auto !important;
  width: inherit !important;
}

.textLayer .endOfContent {
  display: none;
}

.textLayer .highlight {
  background-color: yellow;
  border-radius: 3px;
}

/* Hide the original canvas content */
.react-pdf__Page__canvas {
  display: none !important;
}

.chat-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
 
.dot1, .dot2, .dot3 {
  width: 12px;
  height: 12px;
  background-color: #3498db; /* You can customize the color */
  border-radius: 50%;
  margin: 0 5px;
  animation: bounce 0.6s infinite alternate;
}
 
.dot2 {
  animation-delay: 0.2s;
}
 
.dot3 {
  animation-delay: 0.4s;
}
 
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

.floating-icon {
  animation: float 3s ease-in-out infinite;
  position: fixed; 
  bottom: 20px; 
  right: 20px;
  z-index: 1000; 
  background-color: rgba(0, 93, 141);
  color: white; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
  border-radius: 10px;
}

.ai-suggesstion-card{
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  width: 200px;
  padding: 20px 12px;
  border-radius: 1rem;
  max-height: 88px;
}

.careSathHistoryText:hover{
  background-color: rgb(230 230 230);
}

.chat-container {
  width: 100%; /* 100% width on mobile and smaller screens */
}

@media (min-width: 768px) {
  .chat-container {
      width: 75%; /* 75% width on screens 768px and wider (desktop) */
  }
}

.ai-container {
  background-color: #004171;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px 0px;
  /* height: 100vh; */
}



.ai-heading{
  color: "#ffff"; 
  font-size: 36px;
  font-weight: 700;
}

.ai-service-info-container{
  height: 90%;
}

.ai-service-info{
  max-height:min-content;
  margin-bottom: 40px;
  width: 90% !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 0px 0px 12px 12px;
  text-align: left;
}

.background-style{
  height: 50%;
  width: 24%;
  position: absolute;
  right: 0px;
  top: 15px;
  /* background-image: url('./Assets/Images/Home/qantum-homepage-background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;  */
}


.ai-service-image{
  object-fit: cover;
  width: 100% !important;
  height: 200px;
  /* box-shadow: 0px 8px 8px 0px #75C1FA; */
  border-radius: 12px 12px 0px 0px;
}

.quantum-homepage-container{
  position: relative;
  margin-top: 65px;
  background: linear-gradient(#004171 58%, #F3F8FF 50%);
  
}

@media (max-width: 500px) {
  .ai-service-info{
    margin-bottom: 10px;
    width: 100% !important;
  }
}


@media (max-width: 768px) {
  .ai-container {
    flex-direction: column; /* Stack items vertically on smaller screens */
   padding-top: 50px; 
    align-items: center; /* Center items horizontally */
  }
  .ai-heading{
    font-size: 20px;
  font-weight: 600;
  padding-left: 0px;
    
  }
  .ai-heading-image{
    display: none;
    height: 200px;
    width: auto;
  }
}

.lab-card-container {
  position: relative;
}

.custom-checkbox {
  position: absolute;
  top: -15px;
  left: 10px;
  width: 40px;
  height: 40px;
  background: linear-gradient(135deg, #ff0844, #ffb199);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.custom-checkbox.checked {
  background: linear-gradient(135deg, #fbc632, #ffdd66);
  transform: scale(1.1);
}

.custom-checkbox.checked .anticon {
  color: white;
  font-size: 20px;
}

.custom-checkbox:hover {
  transform: scale(1.1);
}

.otp-input{
  width: 2rem !important;
    height: 2rem;
    margin: 0 0.2rem;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .3);
}
